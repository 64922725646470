import { pcaCandidateInvite } from "../lib/pca/candidate-profile-api";

var APIMapping = {
  /*******************ONboarding APIs *******************/
  login: "/v1/login",
  recruiterLogin: "/v1/recruiter/login",
  signup: "/v1/signup",
  forgotpassword: "/v1/pswd/resetlink",
  resetpassword: "/v1/pswd/reset",
  changePassword: "/v1/pswd/change",
  forgotUsername: "/v1/sendotp",
  otpVerification: "/v1/verifymobile",
  logout: "/v1/logout",
  refreshToken: "/v1/refresh-token",
  sidebarMenu: "/v1/roles/menu",
  getCurrencyCodes: "/v1/currency",
  verifyMobileNo: "/v1/accounts/mobileNo/verify",
  ValidateEmailId: "/v1/accounts/email/validate",
  /******************RECRUITER ONBOARDING ******************/
  recruiterSignup: "/v1/recruiter/signup",
  recruiterVerifyActivationLink: "/v1/recruiter/link/verify",
  verifyUserStatus: "/v1/verify/status",
  salesTicket: "/v1/recruiter/sales/ticket",
  validateRecruiterEmail: "/v1/recruiter/email/validate",
  /*********************Resume APIs ************************/
  uploadResume: "/v1/{id}/profile/resume/upload",
  deleteResume: "/v1/{id}/profile/resume/{fileid}",
  updateResume: "/v1/{id}/profile/resume/{fileid}/update",
  getResumeParserData: "/v1/curatal_ml/parser/resume",
  getResumeParserToken: "/realms/master/protocol/openid-connect/token",

  /**********Profile Photo APIs *************************/
  uploadProfilePhoto: "/v1/{id}/profile/photo/upload",
  updateProfilePhoto: "/v1/{id}/profile/photo/{fileid}/update",
  deleteProfilePhoto: "/v1/{id}/profile/photo/{fileid}",
  /************Profile APIs ******************************/
  uploadProfileDetails: "/v1/{id}/profile",
  listProfileDetails: "/v1/{id}/profile",
  updateProfileDetails: "/v1/{id}/profile",
  getCandidateResumeWithPII: "/v1/accounts/candidate/resume/{candidateid}",
  /*************Video APIs *******************************/
  uploadVideo: "/v1/{id}/profile/video/upload",
  updateVideo: "/v1/{id}/profile/video/{fileid}/update",
  deleteVideo: "/v1/{id}/profile/video/{fileid}",
  updateUser: "/v1/users/{userId}",
  /*************Jobs APIs *******************************/
  getJobList: "/v1/jobs/search/{userId}",
  getJobDetail: "/v1/jobs/{userid}/{jobid}",
  applyJob: "/v1/jobs/{userid}/{jobid}/apply",
  saveJob: "/v1/jobs/{userid}/{jobid}/save",
  industryList: "/v1/industries",
  companyList: "/v1/companies",
  skillsData: "/v1/skills",
  skillScore: "/v1/{id}/skillscore",
  topRecruiters: "/v1/jobs/{userid}/toprecruiters",
  getAdminJobsSearch: "/v1/job/adminsearch",

  /******************SUPPORT APIS********************** */
  getCategory: "/v1/ticket/category",
  submitTicket: "/v1/ticket/raise",
  getUserTickets: "/v1/ticket/users/view/{userid}",
  /******************RECRUITER SUPPORT APIS********************** */
  getRecruiterCategory: "/v1/recruiter/category/list",
  submitRecruiterTicket: "/v1/recruiter/ticket/raise",
  getRecruiterTickets: "/v1/recruiter/tickets/users/{userid}",
  getRecruiterTicketById: "/v1/recruiter/tickets/{ticketid}",
  updateRecruiterTicketStatus: "/v1/recruiter/tickets/{ticketid}",
  dashboardRecruiter: "/v1/recruiter/dashboard",
  dashboardCandidatePushedToInterviewCount:
    "/v1/interview/candidate/pushToInterview/count",
  dashboardCandidatePushedToInterviewStatus:
    "/v1/interview/candidate/pushToInterview/status",
  downloadedProfileStatus: "/v1/interview/candidate/profile/download/count",

  /*********************Recruiter Jobs APIs *********************/
  uploadJD: "/v1/recruiter/job/file/upload",
  uploadQnFile: "/v1/recruiter/job/QNfile/upload",
  deleteQnFile: "/v1/recruiter/job/QNfile/delete",
  getQualifications: "/v1/recruiter/job/qualifications",
  saveJD: "/v1/recruiter/job/save",
  listJDs: "/v1/recruiter/job/list",
  getJDData: "/v1/recruiter/jobs/list",
  submitJD: "/v1/recruiter/job/publish",
  deleteJD: "/v1/recruiter/job/{jobid}",
  listJDTitles: "/v1/recruiter/JDtitle/list",
  getOpenJobsSearch: "/v1/job/opensearch",
  ShiftTimingUrl: "/v1/recruiter/job/shifts",

  /******************Recruiter Profile APIS********************** */
  getRecruiter: "/v1/recruiter/{userid}",
  updateContactDetails: "/v1/recruiter/personalinfo/edit/{userid}",
  getCompany: "/v1/recruiter/company/{companyid}",
  updateCompanyDetails: "/v1/recruiter/company/edit/{companyid}",
  changeRecruiterPassword: "/v1/recruiter/pswd/change",

  /******************Company Logo APIs********************** */
  uploadLogo: "/v1/recruiter/job/logo/upload",
  updateLogo: "/v1/recruiter/job/logo/update",
  deleteLogo: "/v1/recruiter/job/logo/delete",

  /******************Recruiter User Management APIs********************** */
  getRecruiterList: "/v1/recruiter/list",
  addRecruiter: "/v1/recruiter/add",
  editRecruiter: "/v1/recruiter/edit/{recruiterid}",
  deleteRecruiter: "/v1/recruiter/delete/{recruiterid}",
  getBuData: "/v1/recruiter/BU/list",
  getJdTitleData: "/v1/recruiter/JDtitle/list",

  /****************Recruiter Candidte Search APIS ***************************/
  getCandidateList: "/v1/candidate/list/{recruiterid}/{jobid}",
  viewCandidateResume: "/v1/candidate/resume",
  candidateSearch: "/v1/candidate/search/{recruiterid}",
  saveCandidate: "/v1/candidate/save/{recruiterid}/{candidateid}",
  updateCandidateStatus: "/v1/candidate/update/{recruiterid}",
  getCandidateResume: "/v1/candidate/resume/download/{jobid}/{candidateid}",
  getCandidateFeedBackForm: "/v1/candidate/feedback/download/{jobid}/{candidateid}",
  //////////////////////////////////////ADMIN APIS//////////////////////////////////////

  /******************Sales Ticket Mangagement APIs********************** */
  getCompanyList: "/v1/recruiter/ticket/company/list",
  getAllTicket: "/v1/recruiter/sales/tickets",
  getDetailsByTicketId: "/v1/recruiter/sales/tickets/{ticketid}",
  editDetails: "/v1/recruiter/sales/tickets/{ticketid}",

  /****************Recruiter Ticket Management APIs for Admin login************/
  getRecruiterTicketsforAdmin: "/v1/recruiter/tickets",

  /***************Candidate Login SUPPORT APIS********************** */
  getLoginSupportList: "/v1/ticket/list",
  getLoginSupportTicketById: "/v1/ticket/view/{ticketid}",
  updateLoginSupportTicket: "/v1/ticket/change/{ticketid}",

  /**************************Recruiter Account Management APIs for Admin************************************/
  addRecruiterforAdmin: "/v1/admin/add/recruiter",
  getAllCompany: "/v1/admin/company/list",
  getJdsAccountDetails: "/v1/admin/jd/list/{accountid}",
  getAccountDetails: "/v1/admin/account/{accountid}",
  getRecruiterListforAdmin: "/v1/admin/recruiters/list",
  getAccountsListForAdmin: "/v1/admin/accounts/list",

  /***********************Admin Payment and Subscription APIs ***********************************************/
  getStripeProducts: "/v1/stripe/product/list",
  createCustomer: "/v1/admin/create/recruiter",
  uploadCompanyLogoByAdmin: "/v1/admin/company/logo/upload",
  updateCompanyLogoByAdmin: "/v1/admin/company/logo/update",
  deleteCompanyLogoByAdmin: "/v1/admin/company/logo/delete",
  uploadContractFileByAdmin: "/v1/admin/contract/upload",
  upgradeSubscription: "/v1/admin/account/subscription/upgrade",
  cancelSubscription: "/v1/admin/subscription/cancel/{accountid}",

  /*******************Recruiter payment and subscription APIs ********************************************/
  listRecruiterProducts: "/v1/stripe/recruiter/product/list",
  getRecCurrentSubscripton: "/v1/stripe/subscription",
  getBillingHistoryforAdmin: "/v1/stripe/billing/history/{accountid}",
  //upgradeSubscription: "/v1/admin/account/subscription/upgrade",
  //getCurrentSubscriptionforAdmin: "/v1/stripe/subscription/{accountid}",
  createSubscription: "/v1/stripe/subscription/create",
  paySuccess: "/v1/stripe/subscription/pay",

  /******************Alert APIs********************** */
  getAlert: "/v1/alerts/getAlerts",
  updateAlerts: "/v1/alerts/set",

  /******************Admin Post a job APIs********************** */
  saveAdminJob: "/v1/admin/job/save",
  submitAdminJob: "/v1/admin/job/publish",
  listRecruiter: "/v1/admin/recruiters/list",
  listCompanies: "/v1/admin/company/list/account/id",
  /*******************ADMIN WHATS NEW APIs ****************************************************/
  whatsNewGetAllCategotiesApi: "/v1/admin/whatsnew/category/all", //GET ALL CATEROIRES NAME
  whatsNewGetRoles: "/v1/roles", // GET ROLE
  whatsNewApi: "/v1/admin/whatsnew/create",
  whatsNewRecuriter: "/v1/admin/whatsnew",
  whatsNewFeedback: "/v1/admin/whatsnew/feedback",
  whatsNewGetAll: "/v1/admin/whatsnew/all",
  whatsNewEdit: "/v1/admin/whatsnew/id",
  whatsNewDelete: "/v1/admin/whatsnew/id",
  whatsNewGetById: "/v1/admin/whatsnew/id",
  whatsNewSkillcount: "/v1/admin/whatsnew/candidate/count",
  /*******************ADMIN DASHBOARD APIs ****************************************************/
  adminDashboard: "/v1/admin/dashboard",
  ticketMessage: "/v1/recruiter/ticket_msg/response/save",
  getTicketMessage: "/v1/recruiter/ticket_msg/response/list",
  // getAdminPushedToInterviewStats: "/v1/interview/candidate/pushToInterview/status",
  getPrimarySkill: "/v1/interview/pushToInterview/primaryskill",
  getTopCompanies: "/v1/interview/pushToInterview/topCompanies",
  /***********************Report APIs**********************/
  getDomainForRecruiter: "/v1/admin/domain/list",
  getSkillForRecruiter: "/v1/admin/skill/list",
  downloadPredefinedReportsRecruiter: "/v1/recruiter/reports/predefined/{recruiterid}",
  downloadPredefinedReportsAdmin: "/v1/admin/reports/predefined",
  getReportColumnsForAdmin: "/v1/admin/report/columns/list",
  getReportColumnsForRecruiter: "/v1/recruiter/customreport/columns",
  getOtherReportsDataForAdmin: "/v1/admin/customreport",
  getOtherReportsDataForRecruiter: "/v1/recruiter/customreport",
  downloadPredefinedReferralReportsAdmin: "/v1/admin/referralReport",
  downloadPredefinedRedeemReportsAdmin: "/v1/admin/redeemReport",
  downloadInterviewDetails: "/v1/interview/admin/report",
  downloadInterviewDetailsRecruiter: "/v1/interview/recruiter/report",

  /***********************Candidate Dashboard**********************/
  inviteJobs: "/v1/jobs/invited/{candidateid}",
  acceptOrDeclinedJobs: "/v1/accounts/list/invite/status/{userid}",
  topIndustries: "/v1/recruiter/industries/dashboard",
  acceptJobs: "/v1/candidate/invite/accept/{candidateid}",
  getAssessedSkills: "/v1/candidate/assessed_skills/{candidateId}",
  getSpiderGraph: "/v1/accounts/rolerecommendations",

  /***********************Push to Interview  **********************/
  pushtoInterview: "/v1/interview/candidate/pushToInterview",
  /**********************************Candidate - Unsubscribe Email**********************************/
  unsubscribeEmail: "/v1/notification/unsubscribe",

  /***********************In app notification api**********************/
  getNotificationList: "/v1/notification/list",
  getUnreadNotificationCount: "/v1/notification/list/unread",
  updateNotificationReadStatus: "/v1/notification/web",

  /***********************Admin candidate search**********************/
  getAdminCandidateSearch: "/v1/recruiter/candidate/openSearch",

  /***********************Social Signin ******************************/
  googleLogin: "/v1/accounts/google/auth",
  linkedinLogin: "/v1/accounts/linkedin/verify",

  /***********************Candidate interview video ******************************/
  getCandidateInterviewVideo: "/v1/candidate/interview_video/{jobid}/{candidateid}",

  /***********************Primary skill  and secoondary skill in reports******************************/
  getSkillsForReport: "/v1/recruiter/list/reportskill",

  /***********************Open AI Job Summary in Project******************************/
  getAiJobSummary: "/v1/accounts/AIjob_summary",

  /***********************Open AI Job Summary in Project******************************/
  getAiJobDescriptionSummary: "/v1/accounts/AI/job_description",

  /****************************Recruiter and candidate metrics *************************/
  getRecruiterMetrics: "/v1/recruiter/metrics/{userid}",
  getCandidateMetrics: "/v1/candidate/metrics/{userid}",

  /****************************Skill Taxonomy for Admin *************************/
  getSkillsCount: "/v1/accounts/skill/dashboard",
  getAllSkills: "/v1/skills",
  getSkillsDashboard: "/v1/accounts/skill/dashboard",
  getSkillsWithFilter: "/v1/accounts/skill/list",
  addSkill: "/v1/accounts/skill/add",
  updateSkillStatus: "/v1/accounts/skill/status/update",
  deleteSkillById: "/v1/accounts/skill/delete/{skillid}",
  getSimilarSkills: "/v1/accounts/skill/search",
  uploadSkills: "/v1/accounts/skill/upload",
  /****************************Candidate List for Admin *************************/
  candidateList: "/v1/admin/candidate/list",
  currentRole: "/v1/admin/candidate/jobtitle",
  downloadCandiateList: "/v1/admin/candidate/list/csv",
  getInterviewedCandidates: "/v1/recruiter/admin/candidate/list/{jdid}",
  sendInvite: "/v1/recruiter/admin/candidate/status/update",
  /***************************Interview details of  Candidate List for Admin and Recruiter  **********/
  fetchInterviewDetailsForCandidates: "/v1/interview/status",

  deactiveCandidateEmail: "/v1/candidate/status/update",
  reinviteEmail: "/v1/accounts/reactive/link/verify",
  /****************************Candidate My Interview *************************/
  listAllInterview: "/v1/candidate/interviews/list/{userid}",
  mockInterviewbyEmail: "/v1/job/{userid}/mock/request/mail",
  saveCandidateFeedback: "/v1/candidate/feedback/{user_id}",
  getAllCandidatesFeedback: "/v1/candidate/feedback",

  /****************************Salary meter *************************/
  salaryMeterApi: "/v1/accounts/salaryMeter",
  salaryMeterCalculation: "/v1/accounts/salaryMeter/salary/calculate",

  /****************************Candidate Status *************************/
  candidateStatusApi: "/v1/accounts/jobsearch/status",

  /****************************CRM *****************************************/
  createContact: "/v1/accounts/crm/createContact",
  upsertContact: "/v1/accounts/crm/upsertContact",

  /****************************Referral *****************************************/
  addReferral: "/v1/accounts/addReferral/{userId}",
  listReferral: "/v1/accounts/listReferral/{userId}",

  /**********************************PCA Admin**********************************/
  pcaLogin: "/v1/pca/login",
  pcaAdminDashboard: "/v1/pca/dashboard",
  pcaGetProfileByEmail: "/v1/pca/profile/byEmail",
  updateWorkStatus: "/v1/pca/update/workStatus/{candidateId}",
  pcaCandidateInvite: "/v1/pca/candidate/invite/{candidateId}",
  pcaCandidatePersonalInfoUpdate: "/v1/pca/users/{userId}",
  pcaCandidateProfileUpdate: "/v1/pca/update/profile/{id}",
  pcaCandidateList: "/v1/pca/list/candidate",
  pcaDownloadCandiateList: "/v1/pca/list/candidate/csv",
  uploadProfiles: "/v1/pca/upload/resumes",
  getUploadedResumesList: "/v1/pca/resumeUploaded/report",
  listPcaUsers: "/v1/pca/list",
  pcaReport: "/v1/pca/report",
  pcaUpdateAccountDetails: "/v1/pca/edit/{pcaId}",
  addPcaAccount: "/v1/pca/add",
  activatePcaUser: "/v1/pca/activate/{pcaId}",
  deactivatePcaUser: "/v1/pca/deactivate/{pcaId}",
  pcaUpdateCandidateResume: "/v1/accounts/pca/updateResume/{id}",

  /**********************************Candidate Search - save filter**********************************/
  addSaveFilter: "/v1/recruiter/candidate/search/filter/save",
  getSavedFilterList: "/v1/recruiter/candidate/search/filter",
  getCandidatelistSavedFilter: "/v1/recruiter/candidate/search/filter/save/{id}",
  deleteSavedFilter: "/v1/recruiter/candidate/search/filter/save/{id}",

  /**********************************Role Management**********************************/
  getDistinctTemplate: "/v1/resources/templates/all",
  getAllResourceTypes: "/v1/resources/types",
  getMenuByTemplateName: "/v1/resources/templates/name?templateName={templateName}",
  addRole: "/v1/roles",
  editRole: "/v1/roles/{role_id}",
  getRoleById: "/v1/roles/{role_id}",
  getAllRoles: "/v1/roles",
  createUser: "/v1/users",
  getAllUsers: "/v1/accounts/role/users/{role_id}",
  editRoleById: "/v1/roles/{role_id}",
  updateUserByID: "/v1/accounts/role/{userid}",
  deleteRole: "/v1/roles/{role_id}",
  deleteUser: "/v1/users/{user_id}",
  deactivateUser: "/v1/users/{userid}/deactivate",
  activateUser: "/v1/users/{userid}/activate",
  getAllRoleTemplates: "/v1/resources/templates/{templateName}",

  /**********************************Admin - Analytics**********************************/
  getCustomerCount: "/v1/analytics/customers",
  getCanCountByGender: "/v1/analytics/candidate/gender",
  getCanNoticePeriodCount: "/v1/analytics/candidate/noticePeriod",
  getCanExperienceCount: "/v1/analytics/candidate/experience",
  getCanExpectedSalaryCount: "/v1/analytics/candidate/expectedSalary",
  getCanCurrentSalaryCount: "/v1/analytics/candidate/currentSalary",
  getCanSkillCount: "/v1/analytics/candidate/skill",
  getCanAssessedSkillCount: "/v1/analytics/candidate/assessedSkill",
  getCanPrefferedLocationCount: "/v1/analytics/candidate/preferredLocation",
  getCanSkillRatingCount: "/v1/analytics/candidate/rating",
  getCanLoginCount: "/v1/analytics/candidate/login/count",
  getTopTenSkillCount: "/v1/analytics/candidateSkills",
  getBusinessTicketCount: "/v1/analytics/business/tickets",
  getSalesTicketCount: "/v1/analytics/sales/tickets",
  getCandidateTicketCount: "/v1/analytics/candidate/tickets",
  getCanCountbyWorkmode: "/v1/analytics/candidate/workmode",
  getJobCountByNoticePeriod: "/v1/analytics/job/noticePeriod",
  getJobCountByExperience: "/v1/analytics/job/experience",
  getJobCountBySalary: "/v1/analytics/job/salary",
  getJobCountByLocation: "/v1/analytics/job/location",
  getJobCountBySkill: "/v1/analytics/job/skill",

  /**********************************Admin - Assessment**********************************/
  createAssessment: "/v1/assessment/create",
  getAssessments: "/v1/assessment",
  getAssesmentsById: "/v1/assessment/{id}",
  getAssessmentCandidate: "/v1/assessment/{id}/candidates",
  updateAssessmentStatus: "/v1/assessment/{id}/update/status",
  deleteAssessmentById: "/v1/assessment/{id}",
  editAssessment: "/v1/assessment/{id}",

  /**********************************Candidate - Assessment**********************************/
  createDailyAssessment: "/v1/assessment/daily",
  getDailyAssessmentQuesById: "/v1/assessment/daily/question/{id}",
  submitDailyAssessment: "/v1/assessment/daily/submit",
  getDailyAssessmentHistory: "/v1/assessment/daily/history",
  getAllAssessments: "/v1/assessment/candidate",
  getCandidateAssessments: "/v1/assessment/candidate/myAssessment",
  getCandidateAssessmentById: "/v1/assessment/candidate/{id}",
  createAssessmentAttempt: "/v1/assessment/attempt/create",
  getAssessmentAttempt: "/v1/assessment/attempt/{id}",
  getDailyAssessmentScore: "/v1/assessment/daily/score",
  getCompilerLanguageList: "/v1/assessment/compiler/languages",
  getCompilerOutput: "/v1/assessment/compiler/submit",
  submitAssessmentAttempt: "/v1/assessment/attempt/submit",
  getAssessmentResult: "/v1/assessment/candidate/myAssessment/{id}",
  getSavedAssessment: "/v1/assessment/candidate/save",
  saveAssessment: "/v1/assessment/candidate/save",
};

function getAPIMap(name) {
  //console.log("finalurl", process.env.IP_ADDR + APIMapping[name]);
  return "/api" + APIMapping[name];
  //return APIMapping[name];
}

export default getAPIMap;
